@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;700&display=swap');





body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-montserrat {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600; /* Change the weight here */
}

.font-roboto-mono {
  font-family: 'Roboto Mono', monospace;
  font-weight: 400; /* Adjust weight as needed (400, 500, 700) */
}


/* Add this to your global CSS or in-line style tag */
@keyframes scroll {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 100%;
  }
}

.grid-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-size: 40px 40px; /* Size of the grid squares */
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.2) 1px, transparent 1px),
                    linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 1px, transparent 1px);
  opacity: 0.8; /* Make it subtle */
  animation: scroll 60s linear infinite; /* Adjust animation speed */
}

.gradient-bg {
  background: linear-gradient(to bottom, rgba(0, 51, 102, 0.2) 0%, rgba(0, 51, 102, 1) 10%);
}
